<template>
  <div class="page-container">
    <progress-step :step="page.step"></progress-step>
    <div class="page-content">
      <v-card flat>
        <v-card-text>
          <div class="bold-f-s">Excellent!</div>
        </v-card-text>
        <v-card-text>
          Your information is being reviewed. This will only take a few minutes.
        </v-card-text>
        <v-card-text>
          <v-card style="border-radius: 16px">
            <v-card-title :color="icon.color" v-for="icon in iconsStyle" :key="icon.title" style="padding-bottom: 20px">
              {{ icon.title }}
              <v-spacer></v-spacer>
              <v-icon
                :color="icon.color"
                class="mdi" :class="icon.class"
              ></v-icon>
            </v-card-title>
          </v-card>
        </v-card-text>
      </v-card>
    </div>
    <v-dialog persistent max-width="600px" v-model="congratulation">
      <v-card>
        <v-card-text class="bold-f f-s-24 text-center p-t-24">Congratulations!</v-card-text>
        <v-card-text class="text-center">You are pre-qualified for a <span class="bold-f-s">{{toCurrency(loanAmount)}}</span>
          <span v-if="isMultipleLoan"> MULTIPLE-ADVANCE INSTALLMENT LOAN</span><span v-else> loan</span>.
        </v-card-text>
        <v-card-actions>
          <v-btn
            large
            block
            color="primary"
            class="width-full m-b-15"
            @click="next">
            Next
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600px" v-model="showModal">
      <v-card flat>
        <v-card-title>
          Unfortunately, we couldn't verify your bank account at this moment, please choose from:
        </v-card-title>
        <v-card-actions>
          <v-btn
            block
            x-large
            color="primary"
            class="width-150"
            @click="changeBackInformation"
          >
            Modify Bank Information
          </v-btn>
        </v-card-actions>
        <v-card-actions>
          <v-btn
            block
            x-large
            color="primary"
            class="width-150"
            @click="cancelModel"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { OriginationApi } from '../../api/application'
import { Tool, DataType } from '../../js/core'
import ProgressStep from '../../components/progress/ProgressStep'

const tool = new Tool()
export default {
  components: { ProgressStep },
  data () {
    return {
      page: DataType.PAGES.DL_VERIFY,
      congratulation: false,
      showModal: false,
      overlay: true,
      text: '',
      iconsStyle: {
        PERSONAL: {
          title: 'PERSONAL',
          color: 'grey',
          class: 'mdi-dots-horizontal-circle-outline'
        },
        EMPLOYMENT: {
          title: 'EMPLOYMENT',
          color: 'grey',
          class: 'mdi-dots-horizontal-circle-outline'
        },
        BANK: {
          title: 'BANK',
          color: 'grey',
          class: 'mdi-dots-horizontal-circle-outline'
        }
      },
      step: 'bank',
      loanAmount: 0,
      isMultipleLoan: false
    }
  },
  props: {
    sloth: Object
  },
  methods: {
    cancelModel () {
      this.$router.push('/notification/103')
    },
    changeBackInformation () {
      this.showModal = false
      this.$router.push('/authentication/bank')
    },
    toCurrency (num) {
      return tool.formatCurrency(num)
    },
    next () {
      const stepMessage = {
        currect: DataType.PAGES.DL_VERIFY,
        next: DataType.PAGES.LOAN_AMOUNT
      }
      this.$eventBus.$emit('sendStepToSloth', stepMessage)
      OriginationApi.enteredAndLeft(
        localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
        DataType.PAGES.DL_VERIFY.step,
        DataType.PAGES.LOAN_AMOUNT.step,
        localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
      )
      this.$router.push(DataType.PAGES[this.page.next].address)
    },
    onMessageSloth () {
      const _this = this
      this.sloth.client.on('message', function (message) {
        message = message.data
        switch (message.ticket) {
          case 'AUTO-VERIFICATION-PROGRESS': {
            const process = JSON.parse(message.message)
            if (process.status === 1) {
              if (_this.iconsStyle[process.process]) {
                _this.iconsStyle[process.process].class = 'mdi-spin mdi-progress-clock'
                _this.iconsStyle[process.process].color = 'primary'
              }
            }
            if (process.status === 10) {
              if (_this.iconsStyle[process.process]) {
                _this.iconsStyle[process.process].class = 'mdi-progress-check'
                _this.iconsStyle[process.process].color = 'success'
              }
            }
            if (process.status === -1) {
              if (_this.iconsStyle[process.process]) {
                _this.iconsStyle[process.process].class = 'mdi-progress-close'
                _this.iconsStyle[process.process].color = 'error'
                // Verify Error
              }
              _this.showModal = true
            }
            if (process.status === 100) {
              OriginationApi.getLoanById({
                loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
                feature: 'PAYMENT_OPTION'
              }, function (result) {
                _this.loanAmount = result.loanAmount
                _this.congratulation = true
              })
            }
            // 后端定义除bank、employment、personal所有异常的处理
            const other = process.process
            if (other !== 'BANK' && other !== 'EMPLOYMENT' && other !== 'PERSONAL' && process.status === -1) {
              _this.$router.push('/notification/-100')
            }
            break
          }
          case DataType.LoanActionEnum.FORCE_QUIT.value: {
            _this.$router.push('/notification/-100')
            break
          }
          default: {
            break
          }
        }
      })
    },
    startVerify () {
      this.iconsStyle.PERSONAL.class = 'mdi-spin mdi-progress-clock'
      this.iconsStyle.PERSONAL.color = 'primary'
      this.iconsStyle.EMPLOYMENT.class = 'mdi-spin mdi-progress-clock'
      this.iconsStyle.EMPLOYMENT.color = 'primary'
      this.iconsStyle.BANK.class = 'mdi-spin mdi-progress-clock'
      this.iconsStyle.BANK.color = 'primary'
    },
    endVerify () {
      this.iconsStyle.PERSONAL.class = 'mdi-progress-check'
      this.iconsStyle.PERSONAL.color = 'success'
      this.iconsStyle.EMPLOYMENT.class = 'mdi-progress-check'
      this.iconsStyle.EMPLOYMENT.color = 'success'
      this.iconsStyle.BANK.class = 'mdi-progress-check'
      this.iconsStyle.BANK.color = 'success'
    }
  },
  destroyed () {
    this.$eventBus.$off('openSloth')
  },
  async mounted () {
    const _this = this
    const provider = JSON.parse(localStorage.getItem(DataType.COOKIE_KEY.PROVIDER))
    _this.$store.commit('setCurrentPage', this.page)
    const portfolioId = localStorage.getItem(DataType.COOKIE_KEY.PORTFOLIO_ID)
    const requestId = atob(localStorage.getItem(DataType.COOKIE_KEY.REQUEST_ID))
    // const requestCode = atob(localStorage.getItem(DataType.COOKIE_KEY.REQUEST_CODE))
    const wsid = localStorage.getItem(DataType.COOKIE_KEY.WSID)
    const loanId = localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID)
    this.isMultipleLoan = parseInt(localStorage.getItem(DataType.COOKIE_KEY.LOAN_TYPE)) ===
        DataType.LoanTypeEnum.MULTIPLE_ADVANCE_LOAN.value
    await OriginationApi.getLoanById({
      loanId: localStorage.getItem(DataType.COOKIE_KEY.LOAN_ID),
      feature: 'PAYMENT_OPTION'
    }, function (result) {
      _this.loanAmount = result.loanAmount
    }, function () {
    })

    if (tool.isNotEmpty(this.loanAmount) && _this.loanAmount >= 200) {
      const timeout = setTimeout(function () {
        _this.congratulation = true
        clearInterval(timeout)
      }, 2 * 1000)
      return
    }

    const requestParam = {
      loanId: loanId,
      wsid: wsid,
      requestId: requestId,
      portfolioId: portfolioId,
      provider: provider.code
    }
    this.$eventBus.$emit('verification')
    if (this.sloth.client !== null) {
      _this.onMessageSloth()
      OriginationApi.verifyInformation(requestParam)
    } else {
      this.$eventBus.$on('openSloth', () => {
        _this.onMessageSloth()
        OriginationApi.verifyInformation(requestParam)
      })
    }
    // const requestParams = {
    //   loanId: loanId,
    //   wsid: wsid,
    //   requestCode: requestCode,
    //   portfolioId: portfolioId
    // }
    // const category = localStorage.getItem(DataType.COOKIE_KEY.CATEGORY)
    // this.$eventBus.$emit('verification')
    // if (parseInt(category) === DataType.CategoryEnum.RETURNING_CUSTOMER.value) {
    //   OriginationApi.calculateLoanAmount(requestParams, function (result) {
    //     _this.endVerify()
    //     _this.loanAmount = result
    //     _this.congratulation = true
    //   }, function () {
    //     _this.$router.push(DataType.PAGES.NOTIFICATION.address + '-100')
    //   })
    //   _this.startVerify()
    // } else {
    //   if (this.sloth.client !== null) {
    //     _this.onMessageSloth()
    //     OriginationApi.verifyInformation(requestParam)
    //   } else {
    //     this.$eventBus.$on('openSloth', () => {
    //       _this.onMessageSloth()
    //       OriginationApi.verifyInformation(requestParam)
    //     })
    //   }
    // }
  }
}
</script>
